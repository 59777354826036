import "./common/fonts.scss";
import "./common/styles.scss";

import { lazy, Suspense } from 'react';
import FullscreenSpinner from './common/components/fullscreen-spinner';

const DesktopLayout = lazy(() => import("./layouts/desktop"));
const MobileLayout = lazy(() => import("./layouts/mobile"));

export default function App() {

  const isMobile = window.innerWidth < 600

  window.oncontextmenu = function(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
};

  return <Suspense fallback={<FullscreenSpinner />}>
    {isMobile ? ( <MobileLayout />) : <DesktopLayout />}
  </Suspense>

}

